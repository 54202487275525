<template>
 <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-card>

                                    <v-data-table :headers="headers" :search="search" groupable item-key="assetId"
                                        sort-by="เวลา" sort-desc="[true]" group-by="ประเภท" :items="pmRecords" 
                                        single-expand
                                        :items-per-page="-1">
                                        <template v-slot:top>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-toolbar flat>
                                                        <v-toolbar-title class="font-weight-bold">รายการงานบำรุงรักษา
                                                            ประจำวัน</v-toolbar-title>
                                                        <v-spacer />
                                                        <v-col cols="2">
                                                            <v-menu ref="menu" v-model="menu"
                                                                :close-on-content-click="false"
                                                                :return-value.sync="date" transition="scale-transition"
                                                                offset-y min-width="auto">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field class="mt-6" solo v-model="date"
                                                                        label="Picker in menu"
                                                                        prepend-icon="mdi-calendar" dense readonly
                                                                        v-bind="attrs" v-on="on"
                                                                        :disabled="loading"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="date" no-title scrollable>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn text color="primary" @click="menu = false">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn text color="primary"
                                                                        @click="$refs.menu.save(date)">
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <v-btn color="primary" :loading="loading"
                                                                @click="getData">ดึงข้อมูล</v-btn>
                                                        </v-col>
                                                    </v-toolbar>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pa-2">
                                                <v-col v-for="(item, index) in summary" cols="12" md="2" :key="index">
                                                    <v-card outlined height="120px">
                                                        <v-card-text>
                                                            <div class="font-weight-bold text-h5">
                                                                {{ item.ประเภท }}
                                                               
                                                                </div>
                                                                <div class="font-weight-bold text-body-1">
                                                                 Actual:   {{ item.assets.length}}
                                                                </div>
                                                          

                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pa-2">
                                                <v-spacer></v-spacer>
                                                <v-col cols="2">
                                                    <v-text-field solo dense placeholder="ค้นหา"
                                                        v-model="search"></v-text-field>

                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>
</template>

<script>
import * as alasql from 'alasql'
export default {
    computed: {
        pmRecords() {
            return this.$store.state.CMMS.pmRecords
        },
        summary() {
            return alasql(`select [ประเภท], ARRAY(_) AS assets from ? group by [ประเภท]`, [this.pmRecords])
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
    },
    data() {
        return {
            tab: null,
            search: '',
            loading: false,
            headers: [
                { text: 'Asset ID', value: 'assetId' },
                { text: 'Asset Type', value: 'ประเภท' },
                { text: 'Plan', value: 'plantype' },
                { text: 'Result', value: 'ดำเนินการจริง' },
                { text: 'Description', value: 'รายละเอียด' },
                { text: 'Time', value: 'เวลา' },
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true
            this.$store.dispatch('CMMS/GET_PM_RECORDS', { buildingId: this.building.BuildingId, date: this.date }).then(() => {
                this.loading = false
            }).catch((err) => {
                this.loading = false
                console.log(err)
            })
        }
    },
}
</script>
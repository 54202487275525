import {getPMRecords, getPMComparision} from '@/api/'
const state = {
  pmRecords: [],
  pmComparision: []
}
const mutations = {
  SET_PM_RECORDS: (state, pmRecords) => {    
    state.pmRecords = pmRecords
  },
  SET_PM_COMPARISION: (state, pmComparision) => {    
    state.pmComparision = pmComparision
  }
}
const actions = {
  GET_PM_COMPARISION({commit}, data) {
    return new Promise((resolve, reject) => {
      getPMComparision({buildingId: data.buildingId, date: data.date}, message=>{
        commit('SET_PM_COMPARISION', message.data)
        resolve(message.data)
      },error=>{
        reject(error)
      })
    })
  },
  GET_PM_RECORDS({commit}, data) {
    return new Promise((resolve, reject) => {
      getPMRecords({buildingId: data.buildingId, date: data.date}, message=>{
        commit('SET_PM_RECORDS', message.data)
        resolve(message.data)
      },error=>{
        reject(error)
      })
    })
  }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
<template>
    <v-container fluid>

        <Head>
            <template v-slot:title>
                <v-btn icon :to="{ name: 'overview' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-icon>mdi-tools</v-icon>CMMS Dashboard
            </template>
            <template v-slot:subtitle>
                ภาพรวมงานบำรุงรักษา
            </template>
        </Head>
        <v-tabs centered v-model="tab">
            <v-tab>งานบำรุงรักษา รายวัน</v-tab>
            <v-tab>งานบำรุงรักษา เทียบแผน</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
               <PM_DAILY></PM_DAILY>
            </v-tab-item>
            <v-tab-item>
                <PM_MONTHLY></PM_MONTHLY>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import Head from '@/components/layout/Head.vue'
import PM_DAILY from '@/components/cmms/pm_daily.vue'
import PM_MONTHLY from '@/components/cmms/pm_monthly.vue'
import * as alasql from 'alasql'

export default {
    name: 'HelloWorld',
    components: {
        Head,
        PM_DAILY,
        PM_MONTHLY
        // ASSET_TABLE
    },
    computed: {
       
    },
    data() {
        return {
            tab: null,
          
        }
    },
    mounted() {
        // this.getData();
    },
    methods: {
       
    },
}
</script>
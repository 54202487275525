import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Overview from '@/store/modules/overview'
import Asset from '@/store/modules/asset'
import User from '@/store/modules/user'
import Water from '@/store/modules/water'
import Network from '@/store/modules/network'
import Alert from '@/store/modules/alert'
import Member from '@/store/modules/member'
import Energy from '@/store/modules/energy'
import Fire from '@/store/modules/fire'
import Notify from '@/store/modules/notify'
import Email from '@/store/modules/email'
import Report from '@/store/modules/report'
import Acknowledge from '@/store/modules/acknowledge'
import Air from '@/store/modules/air'
import HVAC from '@/store/modules/hvac'
import Waste from '@/store/modules/waste'
import HotWater from '@/store/modules/hot'
import SoftWater from '@/store/modules/soft'
import RainWater from '@/store/modules/rain'
import CMMS from '@/store/modules/cmms'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['User','Energy','Water','Asset','Fire']
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Overview,
    Alert,
    Asset,
    User,
    Water,
    Network,
    Member,
    Energy,
    Fire,
    Notify,
    Email,
    Report,
    Acknowledge,
    Air,
    HVAC,
    Waste,
    HotWater,
    SoftWater,
    RainWater,
    CMMS
  },
  plugins: [vuexLocal.plugin]
})

<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-card>

                        <v-row>
                            <v-col cols="12">
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">รายการงานบำรุงรักษา เทียบแผน
                                    </v-toolbar-title>
                                    <v-spacer />
                                    <v-col cols="2">
                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                            :return-value.sync="date" transition="scale-transition" offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field class="mt-6" solo v-model="date" label="Picker in menu"
                                                    prepend-icon="mdi-calendar" dense readonly v-bind="attrs" v-on="on"
                                                    :disabled="loading"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary" :loading="loading" @click="getData">ดึงข้อมูล</v-btn>
                                    </v-col>
                                </v-toolbar>
                            </v-col>
                        </v-row>
                        <v-row class="pa-2">
                            <v-col v-for="(item, index) in pmComparision" cols="12" md="2" :key="index">
                                <v-card outlined
                                    :class="(item.assets.filter(el => el.hasOwnProperty('recorddate')).length >= item.assets.length) ? 'green' : 'yellow'">
                                    <v-card-text>
                                        <div class="font-weight-bold text-h5 black--text">
                                            {{ item.AssetType }}
                                        </div>
                                        <div class="text-h5 black--text">
                                            {{ item.PlanType }}
                                        </div>
                                        <div class="text-title-1 black--text">
                                            {{ item.Branch }}
                                        </div>
                                        <div class="font-weight-bold text-body-1 black--text">
                                            Plan: {{ item.assets.length }}
                                            Actual: {{item.assets.filter(el => el.hasOwnProperty('recorddate')).length
                                            }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">รายละเอียด</v-toolbar-title>
                                    <v-spacer />
                                </v-toolbar>

                            </v-col>
                            <v-col cols="12">
                                <v-expansion-panels flat>
                                    <v-expansion-panel v-for="(item, index) in pmComparision" :key="index">
                                        <v-expansion-panel-header class="font-weight-bold">
                                            {{ item.AssetType }} | {{ item.PlanType }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-card flat>
                                                <v-card-text>
                                                    <v-data-table :headers="headers" :items="item.assets"
                                                        :items-per-page="-1">

                                                        <template v-slot:item.AssetNumber="{ item }">
                                                            <v-chip v-if="(item.recorddate) ? true : false" color="green"
                                                                dark>{{ item.AssetNumber }}</v-chip>
                                                            <v-chip v-else color="red" dark>{{ item.AssetNumber
                                                                }}</v-chip>
                                                        </template>
                                                    </v-data-table>

                                                </v-card-text>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>


                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

        </v-card-text>

    </v-card>
</template>

<script>
import * as alasql from 'alasql'
export default {
    computed: {
        pmComparision() {
            return this.$store.state.CMMS.pmComparision
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
    },
    data() {
        return {
            tab: null,
            search: '',
            loading: false,
            headers: [
                { text: 'Asset ID', value: 'AssetNumber' },
                { text: 'Plan', value: 'planType' },
                { text: 'Result', value: 'result' },
                { text: 'Description', value: 'note' },
                { text: 'Date', value: 'recorddate' },
                { text: 'Time', value: 'time' },
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true
            this.$store.dispatch('CMMS/GET_PM_COMPARISION', { buildingId: this.building.BuildingId, date: this.date }).then(() => {
                this.loading = false
            }).catch((err) => {
                this.loading = false
                console.log(err)
            })
        }
    },
}
</script>